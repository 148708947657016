import { useSelectorToQueryParam, type Option } from '../shared/useSelectorToQueryParam'

export function usePaxSelector(paxOptions: globalThis.Ref<Array<Option>>, defaultValue = '1', increment = 1) {
  const {
    optionSelected: selectedPax,
    pushQueryParam,

    onValueChanged,
  } = useSelectorToQueryParam(
    {
      label: 'pax',
      defaultValue,
    }
  )

  function pushPaxSelection(currentQuery: Option) {
    pushQueryParam(currentQuery.value)
  }

  const selectedPaxOptionLabel = computed(() => {
    const final = paxOptions.value.filter((opt: Option) => opt.value === selectedPax.value.value)

    if (!final.length) {
      return ''
    }

    return final[0].label
  })

  const hasSelectedPax = computed(() => selectedPax.value.value !== defaultValue)

  const selectedPaxValue = computed(() => {
    if (!selectedPax.value) {
      return '0'
    }

    return selectedPax.value.value
  })

  function addOneToPax() {
    let paxValue = Number(selectedPax.value.value) + increment
    paxValue += paxValue % increment

    pushPaxSelection({label: 'pax', value: paxValue.toString() })
  }

  function subOneToPax() {
    const baseValue = Number(selectedPax.value.value)
    if (baseValue === 0) {
      return
    }

    const paxValue = Math.max(baseValue - increment, 0)
    pushPaxSelection({label: 'pax', value: paxValue.toString() })
  }

  onMounted(() => {
    const current = Number(selectedPax.value.value)

    // Invalid value
    if (Number.isNaN(current)) {
      pushQueryParam(paxOptions.value[0] ? paxOptions.value[0].value : defaultValue)
    }
  })

  function onPaxChanged(fn: VoidFunction) {
    onValueChanged(fn)
  }

  return {
    paxOptions,
    selectedPax,
    hasSelectedPax,
    selectedPaxValue,
    selectedPaxOptionLabel,

    // Funciones para modificar
    pushPaxSelection,
    addOneToPax,
    subOneToPax,

    // Eventos
    onPaxChanged,
  }
}
